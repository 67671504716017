type TypeWithKey<T> = { [key: string]: T };

export const requestDefaultErrorMessage = 'Something went wrong with request';

export const getValidatorErrorMessage = (validatorName: string) => {
  const config: TypeWithKey<string> = {
    BAD_REQUEST: 'Invalid format on sent data',
    UNAUTHORIZED: 'Please log in to continue',
    NOT_AUTHORIZED: 'Please log in to continue',
    FORBIDDEN: 'You don´t have permission to execute that action',
    NOT_FOUND: 'The requested information was not found',
    METHOD_NOT_ALLOWED: 'There has been an error, please try again later',
    INTERNAL_SERVER_ERROR: 'There has been an error, please try again later',
    USER_NOT_FOUND: 'The user has not been found',
    ACCOUNT_NOT_FOUND: 'The account has not been found',
    ERR_BAD_REQUEST: 'Request failed, please try again',
    ERR_NETWORK: 'Network Error',
    DEFAULT_CODE: 'DEFAULT_CODE',
    SERVER_ERROR: 'Internal Server Error',
  };

  if (Object.keys(config).find((property) => property === validatorName)) {
    return config[validatorName];
  }
  return '';
};
