import { getCachedSelectedTeam } from './async-local-storage';

export const isLocalSession = (id: string, isManager?: boolean) => {
  if (!id) {
    return false;
  }

  const selectedTeam = getCachedSelectedTeam();
  if (localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_data${isManager ? '' : 'player'}`)
  || localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`)) {
    const localSession = JSON.parse(
      localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_data${isManager ? '' : 'player'}`) || "{}"
    );
    if (localSession) {
      return true;
    }
  }

  return false;
};
export const isLinupLocalSession = (id: string, isManager?: boolean) => {
  if (!id) {
    return false;
  }

  const selectedTeam = getCachedSelectedTeam();
  if (localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`)) {
    const localSession = JSON.parse(
      localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`) || "{}"
    );
    if (localSession) {
      return true;
    }
  }

  return false;
};

export const getLocalSession = (id: string, isManager?: boolean) => {
  const selectedTeam = getCachedSelectedTeam();
  let d = JSON.parse(localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_data${isManager ? '' : 'player'}`) || "{}");
  return d;
};

export const getLocalSessionTimeStamp =  (id: string, isManager?: boolean) => {
  const selectedTeam = getCachedSelectedTeam();
  let d = localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_time${isManager ? '' : 'player'}`) || "";
  return d || 0;
};

export const getLocalSessionLineup = (id: string, isManager?: boolean) => {
  const selectedTeam = getCachedSelectedTeam();
  let d = JSON.parse(
    localStorage.getItem(`draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`) || "{}"
  );
  return d;
};

export const deleteLocalSession = (id: string, isManager?: boolean) => {
  const selectedTeam = getCachedSelectedTeam();
  localStorage.removeItem(`draft_scorecard_${selectedTeam?.id}_${id}_data${isManager ? '' : 'player'}`);
  localStorage.removeItem(`draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`);
  localStorage.removeItem(`draft_scorecard_${selectedTeam?.id}_${id}_time`);
};

export function storeScoreCardLocalSession(id: string, scorecard: any[], isManager?: boolean) {
  if (id) {
    const selectedTeam = getCachedSelectedTeam();
    localStorage.setItem(
      `draft_scorecard_${selectedTeam?.id}_${id}_data${isManager ? '' : 'player'}`,
      JSON.stringify(scorecard)
    );
  }
}
export function storeScoreCardLocalSessionTimeStamp(id: string, time: number | string) {
  if (id) {
    const selectedTeam = getCachedSelectedTeam();
    localStorage.setItem(
      `draft_scorecard_${selectedTeam?.id}_${id}_time`,
      time.toString()
    );
  }
}
export function storeScoreCardLineupLocalSession(id: string, lineup: any, isManager?: boolean) {
  if (id) {
    const selectedTeam = getCachedSelectedTeam();
    localStorage.setItem(
      `draft_scorecard_${selectedTeam?.id}_${id}_lineup${isManager ? '' : 'player'}`,
      JSON.stringify(lineup)
    );
  }
}

export function getRedirectUrl() {
  return localStorage.getItem('redirect');
}
export function setRedirectUrl(uri: string) {
  return localStorage.setItem('redirect', uri);
}

export function deleteRedirectUrl() {
  return localStorage.removeItem('redirect');
}


export function onboardingState () {
  return JSON.parse(localStorage.getItem('isOnboarded') || "{}");
}

export function getOnboarding (isManager: any, isParent: any, isPlayer: any) {
  const d = onboardingState();
  if(isManager){
    return !!d['manager']
  }
  if(isParent){
    return !!d['parent']
  }
  if(isPlayer){
    return !!d['player']
  }
  return true;
}
export function setOnboarding (manager: any, parent: any, player: any) {
  const s: any = onboardingState();
  const d = JSON.stringify({...s, manager: manager || s?.manager, parent: parent || s?.parent, player: player || s?.player});
  return localStorage.setItem('isOnboarded', d);
}
