
const varsConfig = () => {
  if (window?.location?.href?.includes("staging.statlink.io")) {
    return {
      // REACT_APP_API_BASE_URL: "http://127.0.0.1:8000",
      // REACT_APP_PLAYER_SOCIAL_LINK_DOMAIN: "https://staging.statlink.io",
      // REACT_APP_REPORT_VIEW_DOMAIN: "http://127.0.0.1:8000",
      // REACT_APP_DOMAIN_BASE_URL: "https://staging.statlink.io",
      REACT_APP_API_BASE_URL: "https://api-staging.statlink.io",
      REACT_APP_PLAYER_SOCIAL_LINK_DOMAIN: "https://staging.statlink.io",
      REACT_APP_REPORT_VIEW_DOMAIN: "https://api-staging.statlink.io",
      REACT_APP_DOMAIN_BASE_URL: "https://staging.statlink.io",
      REACT_APP_WS_URL: "wss://api-staging.statlink.io/ws/notifications",
    };
  }
  if (window?.location?.href?.includes("www.statlink.io")) {
    return {
      REACT_APP_API_BASE_URL: "https://api.statlink.io",
      REACT_APP_PLAYER_SOCIAL_LINK_DOMAIN: "https://www.statlink.io",
      REACT_APP_REPORT_VIEW_DOMAIN: "https://api.statlink.io",
      REACT_APP_DOMAIN_BASE_URL: "https://www.statlink.io",
      REACT_APP_WS_URL: "wss://www.api.statlink.io/ws/notifications",
    };
  }
  return {
    REACT_APP_API_BASE_URL: "http://127.0.0.1:8000",
      REACT_APP_PLAYER_SOCIAL_LINK_DOMAIN: "http://localhost:3000",
      REACT_APP_REPORT_VIEW_DOMAIN: "http://127.0.0.1:8000",
      REACT_APP_DOMAIN_BASE_URL: "http://localhost:3000",
      REACT_APP_WS_URL: "ws://127.0.0.1:8000/ws/notifications",
  };
};

export default varsConfig;
