import { memo } from 'react';
import { createPortal } from 'react-dom';

export const shadowFilterId = 'dropshadow';

/*
  SVG filter definitions create global objects that can be referenced by their
  id using the url method. For example the dropshadow can be applied to an SVG
  element by passing url(#dropshadow) to it's filter attribute, or in css by
  using filter: url(#dropshadow);

  The portal is to enable placing this component anywhere in the tree without
  the SVG wrapper causing positioning issues.
*/
const SvgFilterDefinitions = () =>
  createPortal(
    <svg width={0} height={0}>
      <defs>
        <filter id={shadowFilterId}>
          <feDropShadow dx="1.5" dy="1.5" stdDeviation="1" floodOpacity={0.2} />
        </filter>
      </defs>
    </svg>,
    document.body
  );

export default memo(SvgFilterDefinitions);
