import React from 'react';

export type Props = {
  className?: string;
};

const ToolbarHomeicon: React.FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.02 3.34895L3.63 7.54895C2.73 8.24895 2 9.73895 2 10.869V18.279C2 20.599 3.89 22.499 6.21 22.499H17.79C20.11 22.499 22 20.599 22 18.289V11.009C22 9.79895 21.19 8.24895 20.2 7.55895L14.02 3.22895C12.62 2.24895 10.37 2.29895 9.02 3.34895Z" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 18.499V15.499V18.499Z" fill="#386BF6" />
    <path d="M12 18.499V15.499" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default ToolbarHomeicon;
