import { ReactElement, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { Provider } from 'react-redux';
// import createStore from '../../createStore';
import { AuthContextProvider } from "../../context/Auth";
import { ScorecardTimerContextProvider } from "../../context/ScoreCardTimer";
import { TeamSelectorProvider } from "../../context/TeamSelecterContext";
import { WebsocketProvider } from "../../context/WebsocketContext";

type ReactChild = ReactElement | ReactNode | string;
export type Props = {
  children: ReactChild;
};
// const AppProviders = ({ children }: Props) => {
//   // prevent accidental recreation of store and apollo client.
//   const store = useRef(createStore()).current;

//   return (
//     <Provider store={store}>
//       <AuthContextProvider>{children}</AuthContextProvider>
//     </Provider>
//   );
// };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const AppProviders = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ScorecardTimerContextProvider>
        <AuthContextProvider>
        <WebsocketProvider>
          <TeamSelectorProvider>{children}</TeamSelectorProvider>
          </WebsocketProvider>
        </AuthContextProvider>
      </ScorecardTimerContextProvider>
      </LocalizationProvider>
      
    </QueryClientProvider>
  );
};
export default AppProviders;
