/* eslint no-console: 0 */

import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { DOMAIN_BASE_URL, NODE_ENV, API_BASE_URL } from "../../constants";

const LOGS_SESSION_ID = 'logsSessionId';

const getLogsSessionID = () => {
  if(typeof window === 'undefined'){
    return `ssr-${uuidv4()}`
  }
  let sessionId = window.sessionStorage.getItem(LOGS_SESSION_ID);
  if(!sessionId){
    sessionId = uuidv4();
    window.sessionStorage.setItem(LOGS_SESSION_ID, sessionId);
  }
  return sessionId;
};

const exception = async (logInfo = {}, { severity = "INFO" }) => {
  axios.post(`${DOMAIN_BASE_URL}/logs`, { logInfo, severity, environment: NODE_ENV, pointedAPI: API_BASE_URL, sessionId: getLogsSessionID() });
  // axios.post(`http://localhost/logs`, {logInfo, severity});
};

export default exception;
