import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import AppContainer from '../app/AppContainer';
import AppProviders from '../appProviders/AppProviders';

const Root = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <AppProviders>
        <AppContainer />
      </AppProviders>
    </BrowserRouter>
  </ErrorBoundary>
);

export default Root;
