import { Component, ReactNode } from 'react';
import logException from '../../services/logging/exception';
import Error from '../error/Error';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !!error };
  }

  componentDidCatch({ message, stack }: Error) {
    logException({
      location: window.location.pathname + window.location.search,
      message,
      stack,
    }, {severity: 'ERROR'});
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Error isErrorPage />;
    }

    return <>{children}</>;
  }
}
