import React from 'react';

export type Props = {
  className?: string;
};

const ToolbarSearchIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22 22.5L20 20.5" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default ToolbarSearchIcon;
