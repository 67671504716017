import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  clearDbUser,
  getBasicAuthorization,
  setAndPersistDbUserAuthState,
} from "../services/persist-user-infor.service";

import { NEW_API_URI, ROUTES } from "../constants";

import { UserModel } from "../models/account.model";
import { useScorecardTimer } from "./ScoreCardTimer";
import { getService } from "../services/services";
import { accountApiKey } from "../services/queryKeys";
import logException from "../services/logging/exception";

const NotPrivatePath = [
  ROUTES.CHANGE_PASS,
  ROUTES.CONTACT_US,
  ROUTES.FLASHSTAT,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.LOGIN,
  ROUTES.REGISTER,
  // ROUTES.SIGNUP,
  ROUTES.PRIVACY_POLICY,
  ROUTES.LINEUP,
];

export type AuthContextValue = {
  isReady: boolean;
  isManager: boolean;
  isParent: boolean;
  isPlayer: boolean;
  user: UserModel | null;
  notifications: number;
  isMenuOpened: boolean;
  isReadyTimeStamp: number;
  updateNotificationCount: (isAdd?: boolean) => void;
  toggleMenu: () => void;
  logout: () => void;
  logoutWithoutNavigate: () => void;
  setUserAuthState: (token: string) => void;
};

export type Props = {
  children: ReactNode;
};

const AuthContext = React.createContext<undefined | AuthContextValue>(
  undefined
);

function AuthContextProvider({ children }: Props) {
  const token = getBasicAuthorization();
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const { stopTimer } = useScorecardTimer();
  const queryClient = useQueryClient();
  const { isFetched, error, data, dataUpdatedAt } = useQuery({
    queryKey: accountApiKey(),
    queryFn: () => getService(NEW_API_URI.ACCOUNT, token),
    enabled: !!token,
  });
  
  const [isReady, setIsReady] = useState(!token);
  const [isReadyTimeStamp, setTimeStamp] = useState(0);
  const [user, setUser] = useState<UserModel | null>(null);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [roleType, setRoleType] = useState({
    isManager: false,
    isParent: false,
    isPlayer: false,
  });

  useEffect(() => {
    if (!!token) {
      queryClient.invalidateQueries({ queryKey: accountApiKey() });
    }
  }, [token]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    if (!!error) {
      stopTimer();
      clearDbUser();
      setIsReady(true);
      setTimeStamp(Date.now());
      setUser(null);
      setMenuOpened(false);
      logException(
        {
          location: window.location.pathname + window.location.search,
          message: "Account api error",
          stack: error,
        },
        { severity: "ERROR" }
      );

      return;
    }
    if (!!data?.data?.id && !!token) {
      setUser({...data.data});
      setRoleType(
        data.data?.info?.roles?.reduce(
          (acc: any, r: any) => {
            return {
              isManager: acc.isManager || r.id === 1,
              isParent: acc.isParent || r.id === 2,
              isPlayer: acc.isPlayer || r.id === 3,
            };
          },
          {
            isManager: false,
            isParent: false,
            isPlayer: false,
          }
        )
      );
    } else {
      setUser(null);
      stopTimer();
      clearDbUser();
      const isNotRestructed = NotPrivatePath.find((p) => pathname.includes(p));
      if (!isNotRestructed) {
        window.location.href = `${ROUTES.LOGIN}`;
        return;
      }
    }
    setIsReady(true);
    setTimeStamp(Date.now());
    setMenuOpened(false);
  }, [data, isFetched, error, pathname, dataUpdatedAt]);

  useEffect(() => {
    setNotifications(data?.data?.notifications || 0);
  }, [data]);

  const setUserAuthState = (token: string) => {
    setAndPersistDbUserAuthState(token);
    setIsReady(false);
    setTimeStamp(0);
  };

  // memoise context value to avoid rerendering consumers unnecessarily
  const value = useMemo<AuthContextValue>(() => {
    return {
      isReady,
      isManager: roleType.isManager,
      isParent: roleType.isParent,
      isPlayer: roleType.isPlayer,
      user,
      notifications,
      isMenuOpened,
      isReadyTimeStamp,
      updateNotificationCount: (isAdd?: boolean) => {
        const count = (notifications || 0) + (isAdd ? 1 : -1);
        setNotifications(count >= 0 ? count : 0);
      },
      logout: () => {
        stopTimer();
        clearDbUser();
        setUser(null);
        setIsReady(false);
        setTimeStamp(0);
        // navigate(ROUTES.LOGIN, { replace: true });
        window.location.href = `${ROUTES.LOGIN}`;
      },
      logoutWithoutNavigate: () => {
        stopTimer();
        clearDbUser();
        setUser(null);
        setIsReady(false);
        setTimeStamp(0);
      },
      toggleMenu: () => {
        setMenuOpened(!isMenuOpened);
      },
      setUserAuthState,
    };
  }, [user, isReady, isMenuOpened, roleType, dataUpdatedAt, notifications]);

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
}

function useAuth() {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }

  return auth;
}

export { AuthContextProvider, useAuth };
