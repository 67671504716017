import { Link, useNavigate } from "react-router-dom";

import Button from "../atoms/button/Button";
import {
  oopsLabel,
  errorRetryMessage,
  pageNotFoundMessage,
  errorRetryButtonTitle,
  errorRefreshMessage,
  errorRefreshButtonTitle,
  contactSupportLabel,
} from "../../strings";
import styles from "./Error.module.css";
import { ROUTES } from "../../constants";

import RedCardErrorImage from "../../assets/images/red-card-error.svg";

type Props = {
  retryAction?: () => void;
  resourceName?: string;
  isErrorPage?: boolean;
  title?: string;
  message?: string;
};

function Error({ retryAction, resourceName = "page", isErrorPage, title, message }: Props) {
  const navigate = useNavigate();
  let errorMessage;
  let errorButtonTitle;
  let onClickAction;
  if (retryAction) {
    errorMessage = errorRetryMessage(resourceName.toLowerCase());
    errorButtonTitle = errorRetryButtonTitle;
    onClickAction = retryAction;
  } else {
    errorMessage = errorRefreshMessage;
    errorButtonTitle = errorRefreshButtonTitle;
    onClickAction = () => window.location.reload();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.bodyInner}>
          <h3 className={styles.title}>{isErrorPage ? oopsLabel : (title || "404")}</h3>
          <p className={styles.message}>
            {isErrorPage ? errorMessage : (message || pageNotFoundMessage)}
          </p>
          {isErrorPage ? (
            <>
              <Button onClick={onClickAction}>{errorButtonTitle}</Button>
              <span className={styles.message}>Or</span>
              <Link to={ROUTES.CONTACT_US} replace reloadDocument type="button">
                {contactSupportLabel}
              </Link>
            </>
          ) : (
            <Button
              onClick={() => {
                navigate(ROUTES.HOME, { replace: true });
              }}
            >
              Go to home
            </Button>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <img src={RedCardErrorImage} alt="Error" />
      </div>
    </div>
  );
}

export default Error;
