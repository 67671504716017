import React from 'react';
import cx from 'classnames';

import styles from './ShareIcon.module.css';

export type Props = {
  className?: string;
};

const ShareIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={cx(styles.icon, className)}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 20c-0.002 0-0.005 0-0.008 0-1.399 0-2.664 0.579-3.566 1.511l-0.001 0.001-5.762-3.7c0.214-0.538 0.337-1.161 0.337-1.813s-0.124-1.275-0.349-1.847l0.012 0.034 5.762-3.7c0.909 0.929 2.175 1.504 3.575 1.504 2.761 0 5-2.239 5-5s-2.239-5-5-5c-2.761 0-5 2.239-5 5 0 0.003 0 0.006 0 0.009v-0c0.002 0.652 0.126 1.275 0.349 1.847l-0.012-0.035-5.762 3.7c-0.909-0.933-2.178-1.512-3.583-1.512-2.761 0-5 2.239-5 5s2.239 5 5 5c1.404 0 2.674-0.579 3.582-1.511l0.001-0.001 5.762 3.7c-0.212 0.538-0.335 1.16-0.337 1.812v0.001c0 2.761 2.239 5 5 5s5-2.239 5-5c0-2.761-2.239-5-5-5v0zM22 4c1.657 0 3 1.343 3 3s-1.343 3-3 3c-1.657 0-3-1.343-3-3v0c0.007-1.654 1.346-2.993 2.999-3h0.001zM8 19c-1.657 0-3-1.343-3-3s1.343-3 3-3c1.657 0 3 1.343 3 3v0c-0.007 1.654-1.346 2.993-2.999 3h-0.001zM22 28c-1.657 0-3-1.343-3-3s1.343-3 3-3c1.657 0 3 1.343 3 3v0c-0.007 1.654-1.346 2.993-2.999 3h-0.001z"
      fill="white"
    />
  </svg>
);

export default ShareIcon;
