import { memo } from "react";
import cx from 'classnames';
import Button from "../button/Button";

import styles from "./CloseButton.module.css";

const CloseButton = ({
  handleClose,
  isLoading,
  className
}: {
  handleClose: () => void;
  isLoading?: boolean;
  className?: string;
}) => {
  return (
    <Button
      className={cx(styles.close, className)}
      onClick={handleClose}
      title="Close"
      unstyled
      disabled={isLoading}
      aria-label="close"
      role="button"
    />
  );
};

export default memo(CloseButton);
