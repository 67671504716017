export const LocalStorage = {
  setItem: (key: string, value: string) => {
    return localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
  },
  getItem: (key: string) => {
    const localStorageValue = localStorage.getItem(key);
    return localStorageValue
      ? JSON.parse(localStorageValue)
      : localStorageValue;
  },
};

export const cacheSelectedTeam = (team: any) => {
  LocalStorage.setItem('selectedTeam', team);
}

export const getCachedSelectedTeam = (): any => {
  return LocalStorage.getItem('selectedTeam');
}

export const cacheSelectedPlayer = (team: any) => {
  LocalStorage.setItem('selectedPlayer', team);
}

export const getCachedSelectedPlayer = (): any => {
  return LocalStorage.getItem('selectedPlayer');
}
