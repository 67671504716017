import ShareIcon from '../components/atoms/shareIcon/ShareIcon';
import UserFocusIcon from '../components/atoms/userFocus/UserFocusIcon';
import ChartIcon from '../components/atoms/chartIcon/ChartIcon';
import VideoIcon from '../components/atoms/videoIcon/VideoIcon';
import PencilNoteIcon from '../components/atoms/pencilIcon/PencilNoteIcon';

type TypeWithKey<T> = { [key: string]: T };

export enum TabsName {
  BIO = 'bio',
  STATS = 'stats',
  CLIPS = 'clips',
  SHARE = 'share',
  EDIT = 'edit',
}

export const noDataState: TypeWithKey<any> = {
  bio: {
    title: 'This player has no bio',
    desc: 'Add details now!',
    icon: <UserFocusIcon />,
  },
  stats: {
    title: 'Your profile needs data',
    desc: 'Check back later once data has been added.',
    icon: <ChartIcon />,
  },
  clips: {
    title: 'You have no clips',
    desc: 'Add a clip now!',
    icon: <VideoIcon />,
  },
};

export const zeroState: TypeWithKey<any> = {
  bio: {
    title: 'Unlock your bio',
    desc: 'Upgrade now to view your bio!',
  },
  stats: {
    title: 'Unlock your stats',
    desc: 'Upgrade now to view your stats!',
  },
  clips: {
    title: 'Unlock highlight clips',
    desc: 'Upgrade now to upload and share your best plays!',
  },
};

export const tabList = [
  {
    label: 'Bio',
    name: TabsName.BIO,
    className: '',
    isManager: false,
    isPlayer: true,
  },
  {
    label: 'Stats',
    name: TabsName.STATS,
    className: '',
    isManager: true,
    isPlayer: true,
  },
  {
    label: 'Clips',
    name: TabsName.CLIPS,
    className: '',
    isManager: true,
    isPlayer: true,
  },
  {
    label: 'Share',
    name: TabsName.SHARE,
    className: '',
    icon: <ShareIcon />,
    isManager: false,
    isPlayer: true,
  },
  {
    label: 'Edit',
    name: TabsName.EDIT,
    className: '',
    icon: <PencilNoteIcon />,
    isManager: true,
    isPlayer: false,
  },
];

export enum ProfileTabsName {
  STATS = 'stats',
  BIO = 'bio',
  TEAM = 'team',
  SUBSCRIPTIONS = 'subscriptions',
  ACCOUNT = 'account',
}

export const profileTabList = [
  {
    label: 'About me',
    name: ProfileTabsName.BIO,
    className: '',
    isPublic: true,
    isFlashStat: true,
  },
  {
    label: 'Stats',
    name: ProfileTabsName.STATS,
    className: '',
    isPublic: true,
    isFlashStat: true,
  },
  {
    label: 'Team',
    name: ProfileTabsName.TEAM,
    className: '',
    isPublic: true,
    isFlashStat: true,
  },
  {
    label: 'Subscription',
    name: ProfileTabsName.SUBSCRIPTIONS,
    className: '',
    isPublic: false,
    isFlashStat: false,
  },
  {
    label: 'Account',
    name: ProfileTabsName.ACCOUNT,
    className: '',
    isPublic: true,
    isFlashStat: false,
  },
];

export enum EditProfileTabsName {
  INFO = 'info',
  TEAM = 'team',
}

export const editProfileTabList = [
  {
    label: 'Info',
    name: EditProfileTabsName.INFO,
    className: '',
    isManagerView: false,
  },
  {
    label: 'Team',
    name: EditProfileTabsName.TEAM,
    className: '',
    isManagerView: true,
  },
]
