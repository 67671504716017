import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { getBasicAuthorization } from "../services/persist-user-infor.service";
import { useAuth } from "./Auth";
import { WS_URL } from "../constants";

export const WebsocketContext = createContext<any>(undefined);
//                                            ready, value, send

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumer.
const WebsocketProvider = ({ children }: { children: any }) => {
  const connection = useRef(null);
  const { user, updateNotificationCount } = useAuth();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    if (!!connection.current || !user?.id) {
      if (!!connection.current && !user?.id) {
        // @ts-ignore
        connection?.current?.close();
      }
      return;
    }
    const token = getBasicAuthorization();

    const socket = new WebSocket(`${WS_URL}?token=${token}`);

    // Connection opened
    socket.addEventListener("open", (_event) => {
      socket.send("Connection established");
    });

    // Listen for messages
    socket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        if (event.type === "message" && data.type === "notification") {
          setEventData({...data});
          updateNotificationCount(true);
        }
      } catch (er) {
        console.log(er);
      }
    });

    // @ts-ignore
    connection.current = socket;

    return () => {
      // @ts-ignore
      connection.current?.close();
    };
  }, [user?.id, setEventData]);
  
  const value = useMemo(() => ({
    eventData
  }), [eventData])
  return (
    <WebsocketContext.Provider value={value}>
      {children}
    </WebsocketContext.Provider>
  );
};

function useWS() {
  const auth = useContext(WebsocketContext);
  if (auth === undefined) {
    throw new Error("useWS must be used within an WebsocketProvider");
  }

  return auth;
}

export { WebsocketProvider, useWS };
