import { LinearProgress } from '@material-ui/core';
import cx from 'classnames';

import styles from './DsaProgress.module.css';

type Props = {
  mode?: string;
  type?: string;
  label?: string;
  value?: number;
  valueBuffer?: number;
  variant?: any;
  color?: any;
  className?: string;
};

const DsaProgress = ({ type = 'linear', label, className, ...props }: Props) => (
  <div className={cx(styles.container, className)}>
    {label && <label className={styles.label}>{label}</label>}
    <LinearProgress {...props} />
  </div>
);

export default DsaProgress;
