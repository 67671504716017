import Button from '../atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import styles from './Footer.module.css';
import { deleteRedirectUrl } from '../../utils/localStoreateUtil';

type FooterButtonProps = {
  icon: React.ReactNode;
  route: string;
  className?: string;
  label?: React.ReactNode;
  selected?: boolean;
};

const FooterButton = ({ icon, route, className, label, selected }: FooterButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      unstyled
      className={cx(className, { [styles.active]: selected })}
      onClick={() => {
        deleteRedirectUrl();
        navigate(route);
      }}
    >
      {icon}
      {label}
    </Button>
  );
};

export default FooterButton;