import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterButton from "../Footer/FooterButton";
import HomeIcon from "../atoms/toolbarHomeicon/ToolbarHomeicon";
import AnalysisIcon from "../atoms/toolbarAnalysisIcon/ToolbarAnalysisIcon";
import DataSetsIcon from "../atoms/toolbarDataSetsIcon/ToolbarDataSetsIcon";
import SearchIcon from "../atoms/toolbarSearchIcon/ToolbarSearchIcon";
import UserProfileIcon from "../atoms/toolbarUserProfileIcon/ToolbarUserProfileIcon";
import styles from "./Footer.module.css";
import { ROUTES } from "../../constants";
import { useAuth } from "../../context/Auth";
import { useTeamSelector } from "../../context/TeamSelecterContext";
import { ProfileTabsName } from "../../data/profile";

import { TabsName as SubmissionTabsName } from "../../data/submissions";
import { toolbarAnalysis, toolbarDataSets, toolbarHome, toolbarProfile, toolbarSearch } from "../../strings";

const noFooterPathList = [
  ROUTES.REGISTER,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.LOGIN,
  ROUTES.SETUP_TEAM,
  ROUTES.FLASHSTAT,
  ROUTES.FLASHSTATTEAM,
  ROUTES.TEAM_SIGNUP,
];

const appDefinedRouts = Object.values(ROUTES);
const analysisRoutes = [ROUTES.SUBMISSIONS, ROUTES.ANALYSIS];
const dataSetRoutes = [ROUTES.DATA_SETS];
const searchRoutes = [ROUTES.SEARCH];

const Footer = () => {
  const { pathname } = useLocation();
  const { user, isReady, notifications } = useAuth();
  const [hidden, setHidden] = useState(false);
  const p = pathname.split('/')[1];
  const { selectedPlayer } = useTeamSelector();

  useEffect(() => {
    const isShowFooter = !!noFooterPathList.find(p => pathname.startsWith(p))
    setHidden(isShowFooter);
  }, [pathname]);

  //manager buttons: Home | Analysis | Enter Data | Roster | Settings
  //player buttons: Home | Analysis | Enter Data | Submissions | Settings
  return (
    <>
      {!hidden && isReady && user ? (
        <footer className={styles.footer}>
          <div className={styles.container}>
            <div className={styles.bottomNav}>
              <FooterButton
                className={styles.navItem}
                icon={<HomeIcon className={styles.icon} />}
                route={
                  !!selectedPlayer
                    ? `${ROUTES.PROFILE}/${ProfileTabsName.BIO}/${selectedPlayer.id}`
                    : ROUTES.HOME
                }
                selected={
                  !!selectedPlayer
                    ? ROUTES.PROFILE === "/" + p
                    : !appDefinedRouts?.includes('/' + p)
                }
                label={<span className={styles.label}>{toolbarHome}</span>}
              />
              <FooterButton
                className={styles.navItem}
                icon={<AnalysisIcon className={styles.icon} />}
                route={
                  !!selectedPlayer
                    ? `${ROUTES.SUBMISSIONS}/${SubmissionTabsName.OVERVIEW}/${selectedPlayer.id}`
                    : ROUTES.ANALYSIS
                }
                selected={analysisRoutes.includes("/" + p)}
                label={<span className={styles.label}>{toolbarAnalysis}</span>}
              />
              <FooterButton
                className={styles.navItem}
                icon={<DataSetsIcon className={styles.icon} />}
                route={
                  !!selectedPlayer
                    ? `${ROUTES.DATA_SETS}/player/${selectedPlayer.id}`
                    : `${ROUTES.DATA_SETS}`
                }
                selected={dataSetRoutes.includes('/' + p)}
                label={<span className={styles.label}>{toolbarDataSets}</span>}
              />
              <FooterButton
                className={styles.navItem}
                icon={<SearchIcon className={styles.icon} />}
                route={ROUTES.SEARCH}
                selected={searchRoutes.includes('/' + p)}
                label={<span className={styles.label}>{toolbarSearch}</span>}
              />
              <FooterButton
                className={styles.navItem}
                icon={
                  !!user?.info?.photo ? <i className={styles.profileImage}>
                    {!!notifications && <span className={styles.notifyIcon} />}
                    <img
                      src={user?.info?.photo}
                      alt={user?.info.firstName}
                    />
                  </i> :
                    <div className={styles.navItem}>
                      <UserProfileIcon className={styles.icon} />
                    </div>
                }
                route={ROUTES.SETTINGS}
                selected={ROUTES.SETTINGS === "/" + p}
                label={<span className={styles.label}>{toolbarProfile}</span>}
              />
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
