import React from 'react';

export type Props = {
    className?: string;
};

const ToolbarDataSetsIcon: React.FC<Props> = ({ className }) => (
    <svg className={className} width="86" height="73" viewBox="0 0 86 73" fill="none" stroke='none' xmlns="http://www.w3.org/2000/svg">
        <path d="M34.0599 58.8849H13.0599C3.16802 57.4163 -6.23588 39.2927 13.8992 32.113C14.2871 31.9747 14.5735 31.6143 14.5942 31.203L15.0002 23.1349C15.0002 17.733 20.8226 7.95047 36.7627 11.4644C37.1957 11.5598 37.6499 11.3702 37.8888 10.9968C45.7897 -1.35463 70.592 -4.32683 71.4829 23.9188C71.4961 24.338 71.7628 24.7228 72.1515 24.8804C79.8616 28.0052 91.6012 40.3453 75.5603 55.8882" stroke="#ffff" stroke-width="3" stroke-linecap="round" />
        <path d="M56 47V59M56 70.5V59M56 59H44M56 59H67.5" stroke="#ffff" stroke-width="4" stroke-linecap="round" />
    </svg>
);

export default ToolbarDataSetsIcon;
