export enum TabsName {
  OVERVIEW = "overview",
  TIMESTAMS = "timestamps",
  NOTES = "notes",
  BY_CARD = "byCard",
  BY_DATE = "byDate",
}

export const tabList = [
  {
    label: "Overview",
    name: TabsName.OVERVIEW,
    className: "",
  },
  {
    label: "Timestamps",
    name: TabsName.TIMESTAMS,
    className: "",
  },
  {
    label: "Notes",
    name: TabsName.NOTES,
    className: "",
  },
];

export const listPageTabList = [
  {
    label: "By card",
    name: TabsName.OVERVIEW,
    className: "",
  },
  {
    label: "By date",
    name: TabsName.BY_DATE,
    className: "",
  },
];
