// import React from 'react';
import ReactDOM from 'react-dom/client';
import "survey-core/defaultV2.min.css";
import { StylesManager } from "survey-core";
import ReactGA from 'react-ga';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Root from './components/root/Root';

import { publicPrivateInterceptor } from './services/interceptors/index';

// import reportWebVitals from './reportWebVitals';
publicPrivateInterceptor();

StylesManager.applyTheme("modern");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ReactGA.initialize('G-QBKMN60SGX');
root.render(
  <Root />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
