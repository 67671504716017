// import { UserBody } from '../models/account.model';
import axios from "axios";
import { API_BASE_URL } from "../constants";


/**
 * @param  {string} url
 * @param  {any} {arg}
 * @returns Promise
 */
export const postService = async (url: string, arg: any, signal?: any) => {
  return axios.post(`${API_BASE_URL}${url}`, arg, {signal}).then((res) => res.data);
};

/**
 * @param  {string} url
 * @param  {any} {arg}
 * @returns Promise
 */


export const getService = (url: string, _?: any): Promise<any> => {
  const forgotUrl = `${API_BASE_URL}${url}`;
  return axios.get<any>(forgotUrl).then((res) => res.data);
};

export const putService = (url: string, arg: any): Promise<any> => {
  return axios.put(`${API_BASE_URL}${url}`, arg).then((res) => res.data);
};

export const deleteService = (url: string, arg: any): Promise<any> => {
  return axios.delete(`${API_BASE_URL}${url}`, {data: {...arg}}).then((res) => res.data);
};

export const getWithQueryString = (url: string, arg: any): Promise<any> => {
  const query = Object.keys(arg)
    .filter((key) => !!arg[key])
    .map((key) => `${key}=${arg[key]}`);
  const apiUrl = `${API_BASE_URL}${url}?${query.join("&")}`;
  return axios.get<any>(apiUrl).then((res) => res.data);
};
