import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { TeamModel } from "../models/team.model";
import { useAuth } from "./Auth";
import {
  cacheSelectedTeam,
  getCachedSelectedTeam,
  cacheSelectedPlayer,
  getCachedSelectedPlayer,
} from "../utils/async-local-storage";
import { TeamsListTabs } from "../data/team";

export type TeamContextValue = {
  selectedTeam: TeamModel | null | undefined;
  toggleDrawer: (d: boolean) => void;
  open: boolean;
  updateSelectedTeam: (id?: string | undefined) => void;
  updateSelectedPlayer: (p: any) => void;
  selectedPlayer: any;
  isTeamSelecterReady: boolean;
  visibleTabName: TeamsListTabs | null;
  toggleAndShowTab: (t: TeamsListTabs) => void;
  selectedListItemCallbackId: string | null;
  setSelectedListItemCallback: (t: string | null) => void;
};

export type Props = {
  children: ReactNode;
};

const TeamSelector = React.createContext<undefined | TeamContextValue>(
  undefined
);

function TeamSelectorProvider({ children }: Props) {
  const { pathname } = useLocation();
  const { isReady, user, isManager } = useAuth();
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<TeamModel | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<TeamModel | null>(null);
  const [isTeamSelecterReady, setIsTeamSelectorReady] = useState(false);
  const [visibleTabName, setVisibleTabName] = useState<TeamsListTabs | null>(null);
  const [selectedListItemCallbackId, setSelectedListItemCallback] = useState<string | null>(null);

  useEffect(() => {
    setOpen(false);
    setSelectedListItemCallback(null);
  }, [pathname]);

  const updateSelectedPlayer = (playerData?: any) => {
    setOpen(false);
    setSelectedListItemCallback(null);
    let player = null;
    if (!!playerData && typeof playerData === "string") {
      player = user?.players?.find((p: any) => p?.player?.id === playerData);
    } else if (!!playerData?.player?.id && typeof playerData === "object") {
      player = playerData;
    } else {
      player = user?.players?.find(
        (p: any) => p?.player?.id === playerData?.player?.id
      );
    }
    if (!playerData && !player) {
      player = getCachedSelectedPlayer();
      if (!player) {
        player = user?.players?.length ? user?.players[0].player : null;
      }

      setSelectedPlayer(!!player ? player : null);
      cacheSelectedPlayer(!!player ? player : "");
    } else {
      setSelectedPlayer(
        !!player?.player
          ? { ...player.player }
          : !!playerData?.player
          ? { ...playerData?.player }
          : null
      );
      cacheSelectedPlayer(
        (!!player?.player ? player.player : playerData?.player) || ""
      );
    }
  };

  const updateSelectedTeam = (tId?: string | undefined) => {
    setOpen(false);
    setSelectedListItemCallback(null);
    let team: any;
    if(isReady && !isManager && !user?.players?.length){
      setIsTeamSelectorReady(true);
      return;
    }else if (isReady && !!user?.teams?.length) {
      team = user.teams?.find((team: any) => team.id === tId);
      const cachedTeam = getCachedSelectedTeam();
      if (!team) {
        team = user.teams.find((team: any) => team.id === cachedTeam?.id);
      }
      if (!team && !!tId === cachedTeam?.id) {
        team = cachedTeam;
      }

      if (!!team) {
        setSelectedTeam({ ...team });
        cacheSelectedTeam(team);
      } else {
        setSelectedTeam({ ...user.teams[0] });
        cacheSelectedTeam(user.teams[0]);
      }
    }

    // set selected player to null if team selected manually
    if (!!tId) {
      setSelectedPlayer(null);
      cacheSelectedPlayer("");
    }
    //if no team check for the player
    if (!tId && !team && !user?.teams?.length) updateSelectedPlayer();
    if (isReady) setIsTeamSelectorReady(true);
  };

  useEffect(() => {
    if (!user) {
      setSelectedTeam(null);
      setIsTeamSelectorReady(false);
    }
    updateSelectedTeam();
  }, [user]);

  // memoise context value to avoid rerendering consumers unnecessarily
  const value = useMemo<TeamContextValue>(
    () => ({
      selectedTeam,
      toggleDrawer: (s: boolean) => {
        setVisibleTabName(null);
        setSelectedListItemCallback(null);
        setOpen(s);
      },
      open,
      updateSelectedTeam,
      isTeamSelecterReady,
      updateSelectedPlayer,
      selectedPlayer,
      visibleTabName,
      toggleAndShowTab: (tab: TeamsListTabs) => {
        setSelectedListItemCallback(null);
        setOpen(true);
        setVisibleTabName(tab);
      },
      selectedListItemCallbackId,
      setSelectedListItemCallback,
    }),
    [
      user,
      updateSelectedTeam,
      open,
      selectedTeam,
      setOpen,
      isTeamSelecterReady,
      selectedPlayer,
      updateSelectedPlayer,
      visibleTabName,
      setVisibleTabName,
      selectedListItemCallbackId,
      setSelectedListItemCallback,
    ]
  );

  return (
    <>
      <TeamSelector.Provider value={value}>{children}</TeamSelector.Provider>
    </>
  );
}

function useTeamSelector() {
  const team = useContext(TeamSelector);
  if (team === undefined) {
    throw new Error(
      "useTeamSelector must be used within an TeamSelectorProvider"
    );
  }

  return team;
}

export { TeamSelectorProvider, useTeamSelector };
