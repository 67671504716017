import { memo } from 'react';
import { Helmet } from 'react-helmet';

import { metaTitle } from '../../strings';

type Props = {
  title?: string;
  description?: string;
  thumbnail?: string;
};

function Meta({ title, description, thumbnail }: Props) {
  return (
    <Helmet>
      <title>
        {title ? `${title} | ` : ''}
        {metaTitle}
      </title>
      {description && <meta name="description" content={description} />}

      {title && <meta itemProp="name" content={title} />}
      {description && <meta itemProp="description" content={description} />}
      {thumbnail && <meta itemProp="image" content={thumbnail} />}

      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {thumbnail && <meta name="twitter:image:src" content={thumbnail} />}

      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {thumbnail && <meta property="og:image" content={thumbnail} />}
    </Helmet>
  );
}

export default memo(Meta);
