import axios, { AxiosRequestConfig } from "axios";
import toast from "react-hot-toast";
import { ROUTES } from "../../constants";
import {
  clearDbUser,
  getBasicAuthorization,
} from "../persist-user-infor.service";
import {
  getValidatorErrorMessage,
  requestDefaultErrorMessage,
} from "../../utils/requestValidationMessages";

const ServerErrors = [500, 501, 502, 503, 505, 506, 507, 508, 510, 511];

export const publicPrivateInterceptor = () => {
  const redirect = () => {
    clearDbUser();
    window.location.href = `${ROUTES.LOGIN}`;
  };

  const updateHeader = (request: AxiosRequestConfig<any>) => {
    const token = getBasicAuthorization();
    const newHeaders = {
      Authorization: token || "",
      "Content-Type": request.headers
        ? request.headers["Content-Type"] || "application/json"
        : "application/json",
    };

    request.headers = newHeaders;
    request.responseType = "json";
    return request;
  };

  axios.interceptors.request.use(function (request) {
    if (
      request.headers?.Authorization ||
      !!request.url?.includes("amazonaws.com")
    ) {
      return request;
    }
    return updateHeader(request);
  });

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.success === false && !!response.data?.message) {
        toast.dismiss();
        toast.error(response.data.message);
      }
      return response;
    },
    function (error) {
      if (error.message === "canceled") return Promise.resolve();

      if (error.response?.status === 401 || error.response?.status === 403) {
        if (!error.request?.responseURL?.includes("amazonaws.com")) redirect();
        return;
      }
      toast.dismiss();
      if (ServerErrors.find((e) => error.response?.status === e)) {
        // window.location.href = ROUTES.HOME;
        toast.error(getValidatorErrorMessage("SERVER_ERROR"));
        return Promise.reject();
      }

      if (error.response?.status === 504) {
        toast.error(getValidatorErrorMessage("GATEWAY_TIMEOUT"));
      } else if (error.response?.data?.error?.code || error?.code) {
        toast.error(
          getValidatorErrorMessage(
            error.response?.data?.error?.code || error?.code || "DEFAULT_CODE"
          )
        );
      } else {
        toast.error(error.message || requestDefaultErrorMessage);
      }
      return Promise.reject(error);
    }
  );
};
