import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { storeScoreCardLocalSessionTimeStamp } from "../utils/localStoreateUtil";

type TypeWithKey<T> = { [key: string]: T };

export type ScorecardTimerContextValue = {
  state: TypeWithKey<any>;
  updateState: () => void;
  startTimer: (id: string) => void;
  pauseTimer: (id: string) => void;
  updateTimer: (id: string, time: number) => void;
  stopTimer: () => void;
  resetTimer: (id: string) =>  void;
};

const ScorecardTimerContext = React.createContext<
  undefined | ScorecardTimerContextValue
>(undefined);

export type Props = {
  children: ReactNode;
};

const maxLimit = 320 * 60 * 1000;

function ScorecardTimerContextProvider({ children }: Props) {
  const [state, setState] = useState<any>({});

  const updateState = () => {
    let s: any = {};
    let hasUpdate = false;
    if (!Object.keys(state).length) {
      return;
    }
    Object.keys(state).map((key: string) => {
      if (!state[key]?.isPaused && state[key]?.time < state[key]?.maxLimit) {
        s[key] = { ...state[key], time: (state[key]?.time || 0) + 1000 };
        hasUpdate = true;
        storeScoreCardLocalSessionTimeStamp(key, (state[key]?.time || 0) + 1000);
      } else {
        s[key] = { ...state[key] };
      }
    });
    if (hasUpdate) setState(s);
  };

  useEffect(() => {
    let interval: any = null;
    if (!!Object.keys(state)) {
      interval = setInterval(() => {
        updateState();
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [state]);

  // memoise context value to avoid rerendering consumers unnecessarily
  const value = useMemo<ScorecardTimerContextValue>(
    () => ({
      state,
      updateState: () => {},
      startTimer: (id: string) => {
        if (!state[id]) {
          setState((prev: any) => ({
            ...prev,
            [id]: {
              isPaused: false,
              time: 0,
              maxLimit,
            },
          }));
        }else {
          setState((prev: any) => ({
            ...prev,
            [id]: {
              ...prev[id],
              isPaused: false,
            },
          }));
        }
      },
      pauseTimer: (id: string) => {
        if (!!state[id]) {
          setState((prev: any) => ({
            ...prev,
            [id]: {
              ...prev[id],
              isPaused: true,
            },
          }));
        }
      },
      updateTimer: (id: string, time: number) => {
        // if (!!state[id]) {
          setState((prev: any) => ({
            ...prev,
            [id]: {
              ...(!!state[id] ? prev[id] : {isPaused: true, maxLimit}),
              time,
            },
          }));
        // }
      },
      resetTimer: (id: string) => {
        setState((prev: any) => ({
          ...prev,
          [id]: null,
        }));
      },
      stopTimer: () => {
        setState({});
      },
    }),
    [state]
  );

  return (
    <>
      <ScorecardTimerContext.Provider value={value}>
        {children}
      </ScorecardTimerContext.Provider>
    </>
  );
}

function useScorecardTimer() {
  const timer = useContext(ScorecardTimerContext);
  if (timer === undefined) {
    throw new Error(
      "useScorecardTimer must be used within an ScorecardTimerContextProvider"
    );
  }

  return timer;
}

export { ScorecardTimerContextProvider, useScorecardTimer };
