import React from 'react';

export type Props = {
  className?: string;
};

const ToolbarUserProfileIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 10.2614 9.73858 12.5 12.5 12.5Z" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.0901 22.5C21.0901 18.63 17.2402 15.5 12.5002 15.5C7.76015 15.5 3.91016 18.63 3.91016 22.5" stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default ToolbarUserProfileIcon;
