import React from 'react';

export type Props = {
  className?: string;
};

const ToolbarAnalysisIcon: React.FC<Props> = ({ className }) => (
  <svg className={className} fill="none" width="28.88" height="23.01" xmlns="http://www.w3.org/2000/svg" stroke="null" data-name="Layer 1">
    <g stroke="null">
      <path stroke="null" id="svg_1" fill-rule="evenodd" d="m84.53359,52.14272zm-21.79153,12.493" />
      <path transform="rotate(90 3.59159 17.2934)" stroke="#ffff" id="svg_2" d="m0.75145,15.66842l5.68026,0l0,0c0.74413,0 1.34737,0.72754 1.34737,1.625c0,0.89746 -0.60324,1.625 -1.34737,1.625l-5.68026,0l0,0c-0.74413,0 -1.34737,-0.72754 -1.34737,-1.625c0,-0.89746 0.60324,-1.625 1.34737,-1.625z" />
      <path transform="rotate(90 24.0916 13.6372)" stroke="#ffff" id="svg_6" d="m18.68685,12.01219l10.80946,0l0,0c1.41606,0 2.56401,0.72753 2.56401,1.625c0,0.89746 -1.14795,1.625 -2.56401,1.625l-10.80946,0l0,0c-1.41606,0 -2.56401,-0.72754 -2.56401,-1.625c0,-0.89747 1.14795,-1.625 2.56401,-1.625z" />
      <path transform="rotate(90 10.2166 14.4497)" stroke="#ffff" id="svg_7" d="m5.53249,12.82467l9.3682,0l0,0c1.22725,0 2.22214,0.72753 2.22214,1.625c0,0.89746 -0.99489,1.625 -2.22214,1.625l-9.3682,0l0,0c-1.22725,0 -2.22214,-0.72754 -2.22214,-1.625c0,-0.89747 0.99489,-1.625 2.22214,-1.625z" />
      <path transform="rotate(90 17.1541 16.5434)" stroke="#ffff" id="svg_8" d="m14.102,14.91842l6.10417,0l0,0c0.79966,0 1.44791,0.72753 1.44791,1.625c0,0.89746 -0.64825,1.625 -1.44791,1.625l-6.10417,0l0,0c-0.79966,0 -1.44791,-0.72754 -1.44791,-1.625c0,-0.89747 0.64825,-1.625 1.44791,-1.625z" />
      <path transform="rotate(-35 6.09781 6.13751)" stroke="#ffff" id="svg_9" d="m3.27887,5.39455l5.63788,0l0,0c0.73858,0 1.33731,0.33264 1.33731,0.74296c0,0.41033 -0.59873,0.74296 -1.33731,0.74296l-5.63788,0l0,0c-0.73858,0 -1.33731,-0.33263 -1.33731,-0.74296c0,-0.41033 0.59874,-0.74296 1.33731,-0.74296z" />
      <path transform="rotate(-41 20.256 5.12909)" stroke="#ffff" id="svg_11" d="m17.43704,4.3728l5.63788,0l0,0c0.73857,0 1.33731,0.33861 1.33731,0.75629c0,0.41769 -0.59874,0.75629 -1.33731,0.75629l-5.63788,0l0,0c-0.73858,0 -1.33731,-0.3386 -1.33731,-0.75629c0,-0.41768 0.59873,-0.75629 1.33731,-0.75629z" />
      <path transform="rotate(36 13.5164 5.61853)" stroke="#ffff" id="svg_12" d="m10.76294,4.85244l5.50689,0l0,0c0.72141,0 1.30624,0.343 1.30624,0.76609c0,0.4231 -0.58483,0.76609 -1.30624,0.76609l-5.50689,0l0,0c-0.72142,0 -1.30624,-0.34299 -1.30624,-0.76609c0,-0.42309 0.58482,-0.76609 1.30624,-0.76609z" />
      <ellipse fill="black" stroke="#ffff" ry="1.8125" rx="1.75" id="svg_15" cy="7.61999" cx="16.55999" />
      <ellipse fill="black" stroke="#ffff" ry="1.8125" rx="1.75" id="svg_16" cy="3.43248" cx="9.935" />
      <ellipse fill="black" stroke="#ffff" ry="1.8125" rx="1.75" id="svg_17" cy="8.49498" cx="3.06" />
      <ellipse fill="black" stroke="#ffff" ry="1.8125" rx="1.75" id="svg_20" cy="2.36999" cx="23.56" />
    </g>
  </svg>
);

export default ToolbarAnalysisIcon;
