import { LocalStorageEntities, UserInfoModel } from '../models/account.model';

export const setAndPersistDbUserState = (userInfo: UserInfoModel) => {
  localStorage.setItem(
    LocalStorageEntities.user_info,
    JSON.stringify({ ...{ id: 1 }, ...userInfo })
  );
};

export const setAndPersistDbUserAuthState = (token: string) => {
  localStorage.setItem(LocalStorageEntities.user_auth, token);
};

export const clearDbUser = () => {
  localStorage.setItem(LocalStorageEntities.user_info, '');
  localStorage.setItem(LocalStorageEntities.user_auth, '');
  localStorage.setItem(LocalStorageEntities.selected_player, '');
  localStorage.setItem(LocalStorageEntities.selected_team, '');
};

/**
 * @desc it checks if the logged user has a proper token and is saved inside the app
 * @return Observable<string>
 */
export const getBasicAuthorization = () => {
  const userAuth = localStorage.getItem(LocalStorageEntities.user_auth)
    ? (localStorage.getItem(LocalStorageEntities.user_auth) as string)
    : null;

  return userAuth;
};
