import React from 'react';
import cx from 'classnames';

import styles from './PencilIcon.module.css';

export type Props = {
  className?: string;
};

const PencilNoteIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={cx(styles.icon, className)}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.712 7.287l-4-4c-0.183-0.181-0.435-0.293-0.712-0.293s-0.53 0.112-0.713 0.293l-12 12c-0.178 0.182-0.288 0.432-0.288 0.707 0 0.002 0 0.004 0 0.006v-0 4c0 0.552 0.448 1 1 1v0h4c0.002 0 0.004 0 0.006 0 0.275 0 0.525-0.11 0.707-0.288l-0 0 12-12c0.181-0.183 0.293-0.435 0.293-0.713s-0.112-0.53-0.293-0.713l0 0zM15.588 19h-2.588v-2.587l8-8 2.587 2.588zM25 9.588l-2.587-2.588 1.587-1.588 2.587 2.588zM28 15v11c0 1.105-0.895 2-2 2v0h-20c-1.105 0-2-0.895-2-2v0-20c0-1.105 0.895-2 2-2v0h11c0.552 0 1 0.448 1 1s-0.448 1-1 1v0h-11v20h20v-11c0-0.552 0.448-1 1-1s1 0.448 1 1v0z"
      fill="white"
    />
  </svg>
);

export default PencilNoteIcon;
